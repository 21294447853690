<template>
  <div class="homepage">
    <div class="header">
      <img src="https://cdn.asthriona.com/cloudsdaleradio/CRlogo.png" alt="Cloudsdale Radio" />
    </div>
    <div class="spacer"></div>
    <div class="headline">
      <h1>Cloudsdale Radio has shutdown.</h1>
      <hr>
      <h3>Thank you for those 7 years!</h3>
    </div>
    <div class="invisible">
      Fun fact: the station never actually stopped! A stream is still available <a
        href="https://frrelay.cloudsdaleradio.com/" target="_blank" rel="noopener noreferrer">here :)</a>.
      This is our backup relay, designed to take over if the main relay went offline or stopped broadcasting. So, you
      can still listen to the station if you'd like!
      When we shut down our main broadcaster, the backup relay automatically took over and has been running ever since.
      No one ever bothered to turn it off, and honestly, we enjoy having it around every now and then. :)
    </div>
  </div>
</template>

<script>
export default {
  name: "Offline-comp",
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

#app {
  background-image: url("https://cdn.asthriona.com/cloudsdaleradio/CRbackground.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.header {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 100px;
}

h1,
h3,
hr {
  margin-top: 10px;
  margin-bottom: 10px;

}

.homepage {
  font-family: 'Roboto', sans-serif;
  background-image: url("https://cdn.asthriona.com/cloudsdaleradio/CRbackground.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  justify-content: center;
  height: 100vh;
  color: #000;
  text-align: center;
  padding: 20px;
}

.headline {
  background-color: #fff;
  margin-left: 30%;
  margin-right: 30%;
  padding: 20px;
  border-radius: 10px;
}

.invisible {
  display: none;
}
</style>