<template>
  <div class="player-popup">
    <div class="player-popup-content">
      <div class="player-popup-content-close"></div>
      <v-card align="center" justify="center" class="middle">
        <v-card-text>
          <iframe
            src="https://broadcaster.cloudsdaleradio.com/public/cloudsdale_radio/embed?theme=light"
            frameborder="0"
            allowtransparency="true"
            style="width: 100%; min-height: 150px; border: 0"
          ></iframe>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupPage",
}
</script>

<style scoped>
.middle {
    position: absolute;
    width: 100%;
    top: 25%;
}
#popup-player {
    overflow: hidden;
}
</style>