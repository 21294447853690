<template>
    <div class="request">
        <iframe src="https://broadcaster.cloudsdaleradio.com/public/cloudsdale_radio/embed-requests?theme=light" frameborder="0" allowtransparency="true" style="width: 100%; min-height: 850px; border: 0;"></iframe>
    </div>
</template>

<script>
export default {
    name: "RequestPage",
}
</script>

<style scoped>
    iframe {
        background-color: #fff;
    }
</style>