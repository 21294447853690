<template>
  <div class="cloudsdaleRadio">
      <v-app dark>
        <!-- <TheNavBar :metadata="metadata" dark/> -->
        <v-main>
          <!-- <router-view :streams="streams" :metadata="metadata" />   -->
          <Offline />
        </v-main>
        <!-- <TheFooter dark/> -->
      </v-app>
  </div>
</template>

<script>
// import TheNavBar from "./components/PageComp/TheNavBar.vue";
// import TheFooter from "./components/PageComp/TheFooter.vue";
import axios from 'axios';
import Offline from "./components/PageComp/Offline.vue";
export default {
  name: "App",
  components: {
    // TheNavBar,
    // TheFooter,
    Offline,
  },
  data() {
    return {
      background: "",
      user: null,
    };
  },
  mounted() {
    // First execution on startup
    this.$vuetify.theme.dark = false;
    // this.checkStreams();
    this.getMetadata();
    // Timing dependent stuff
    setInterval(() => {
      this.getMetadata();
    }, 5000);
  },
  methods: {
    getMetadata() {
      console.log("getMetadata")
      axios.get(`${process.env.VUE_APP_PLAYER}/nowplaying`)
      .then((res) => {
        console.log(res.data)
        this.metadata = res.data;
      })
      .catch(err => {
        if(process.env.VUE_APP_PRODUCTION == true) {
          console.error("Something went wrong, the broadcaster might be down.")
        } else {
          console.error("Error: ", err);
        }
        this.metadata = { 
          listeners: 0,
          now: {
            stationId: null,
            isLive: false,
            isRequested: false,
            title: "Station Offline",
            Artist: " ",
            album: " ",
            art: "https://cdn.asthriona.com/cloudsdaleradio/CR_OFFLINE.png",
          }
        }
      })
    }
  }
};
</script>

<style>
.text-center {
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}
#app {
  background-image: url("https://cdn.asthriona.com/cloudsdaleradio/CRbackground.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.imgNav {
  margin-right: 10px;
  border-radius: 25%;
  border-style: solid;
  border-width: 2px;
  border-color: aliceblue;
}
.nolink {
  text-decoration: none;
  color: ivory;
}
</style>