import { render, staticRenderFns } from "./NavAudio.vue?vue&type=template&id=5a9c54c2&scoped=true"
var script = {}
import style0 from "./NavAudio.vue?vue&type=style&index=0&id=5a9c54c2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a9c54c2",
  null
  
)

export default component.exports